import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllCurrency = (queryRequest: string) =>
  get(`${config.API_URL}/currency?${queryRequest}`);

export const getSpecificCurrency = (id: string) =>
  get(`${config.API_URL}/currency/${id}`);

export const createCurrency = (payload: object) =>
  post(`${config.API_URL}/currency`, payload);

export const updateCurrency = (id: string, payload: object) =>
  patch(`${config.API_URL}/currency/${id}`, payload);

export const deleteCurrency = (id: string) =>
  Delete(`${config.API_URL}/currency/${id}`);

export const updateCurrencyStatusBulk = (payload: object) =>
  patch(`${config.API_URL}/currency/bulk/active-inactive`, payload);

export const getAllExchangeRates = (queryRequest: string) =>
  get(`${config.API_URL}/exchange-rates?${queryRequest}`);

export const getSpecificExchangeRates = (id: string) =>
  get(`${config.API_URL}/exchange-rates/${id}`);

export const createExchangeRates = (payload: object) =>
  post(`${config.API_URL}/exchange-rates`, payload);

export const updateExchangeRates = (id: string, payload: object) =>
  patch(`${config.API_URL}/exchange-rates/${id}`, payload);


