import React, { useEffect, useMemo, useRef, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import CreateTripsStickyHeader from "../components/Create/StickyHeader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import {
  createTrip,
  getAllTripForm,
  getAllTripFormEdit,
  getTripValidateApproverFlowType,
  submitTrip,
  updateTrip,
} from "@services/trips.service";
import { RenderForm } from "@components/common/FormBuilder/render-form";
import TripsCreateHeader from "../components/Create/tripsHeader";
import TripsCreateFlightList from "../components/Create/Flight/flight-section";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import {
  concatString,
  formatText,
  removeEmptyProperties,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import {
  FormAppendDataRequestHelper,
  FormRequestHelper,
} from "@components/common/FormBuilder/request-helper";
import {
  QueryRequestHelper,
  removeNullInObject,
} from "@common/query-request-helper";
import ReportSubmitModal from "@components/Reports/components/ReportSubmitPopup";
import TripCreateHotelSection from "../components/Create/hotel/hotel-section";
import TripCreateCarSection from "../components/Create/car/car-section";
import TripCreateTrainSection from "../components/Create/train/train-section";
import TripCreateBusSection from "../components/Create/bus/bus-section";
import { getAllCurrency } from "@services/currency.service";
import SubmitConfirmModal from "@common/popup/SubmitConfirmModal";
import TripsTabNavigation from "./tab-navigation";
import {
  generateBusPayload,
  generateCarPayload,
  generateFlightCreatePayload,
  generateHotelPayload,
  generatePrefillTripRequest,
  generateTrainPayload,
} from "./trip-request";
import { useLocation } from "react-router-dom";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";
import { airTravelClass, travelType } from "@common/constants";
import { getAllTravelDocumentsUser } from "@services/settings.service";
import { get } from "@services/helpers/http-handler";
import { convertDateAsString } from "@common/date-helpers";
import { NavLink } from "react-router-dom";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import { mediaUpload } from "@services/upload.service";
import DocumentImageList from "@common/FileUpload/document-image-list";
import FileLogSvgComponent from "@assets/svg-components/file-log";
import DeleteSvgComponent from "@assets/svg-components/delete";

interface TravelDocumentsUser {
  passport: Document[];
  visa: Document[];
}

interface Document {
  id: string;
  document_type: string;
  document_expires_on: string;
  // Add other properties as needed
}

function ClientTripsCreate(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [form, setForm] = useState([]);
  const [request, setRequest] = useState({
    air_travel_type: "one_way",
    class: "",
    time_preference: "",
    seat_type: "",
    meal_type: "",
  });
  const [itineraries, setItenaries] = useState([]);
  const [hotelItenaries, setHotelItenaries] = useState([]);
  const [carItenaries, setCarItenaries] = useState([]);
  const [trainItenaries, setTrainItenaries] = useState([]);
  const [busItenaries, setBusItenaries] = useState([]);
  const [flightPreference, setFlighPreference] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);


  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      getDefaultData();
      getData();
    }
  }, [props.permissionAPITriggered]);

  const travelTypeField: any = useMemo(() => {
    return form.find((field: any) => field.field_name === "travel_type");
  }, [form]);

  useEffect(() => {
    if (travelTypeField) {
      getTravelTypeData();
    } else {
      console.log("Travel type field is not present, form works as it is.");
    }
  }, [travelTypeField]);

  useEffect(() => {
    const getDestinationCountryValue = (form: any) => {
      const destinationField = form.find(
        (item_form: any) => item_form.field_name === "destination_country"
      );
      return destinationField?.field_value || "";
    };

    const destinationValue = getDestinationCountryValue(form);
    if (destinationValue) {
      getTravelDocumentUser(destinationValue);
    }
  }, [form]);

  const [tripLimit, setTripLimit] = useState({});
  const getTravelTypeData = async () => {
    const travelTypeValue: any = travelTypeField.field_value;
    try {
      let resp = await getAllTripForm("");
      setTripLimit(getValue(resp, `data.trip_limit`, {}));
      const flightOptions = [];
      const flightLimit = getValue(resp, `data.flight_limit`, "").find(
        (flight: any) => flight.travel_type === travelTypeValue
      );

      if (flightLimit) {
        if (flightLimit.economy) {
          flightOptions.push({
            value: "economy",
            label: formatText("economy"),
          });
        }
        if (flightLimit.premium_economy) {
          flightOptions.push({
            value: "premium_economy",
            label: formatText("premium_economy"),
          });
        }
        if (flightLimit.business) {
          flightOptions.push({
            value: "business",
            label: formatText("business"),
          });
        }
        if (flightLimit.first) {
          flightOptions.push({ value: "first", label: formatText("first") });
        }
      }

      if (!getValue(resp, `data.trip_limit.is_active`, false)) {
        setFlighPreference(airTravelClass);
      } else {
        setFlighPreference(flightOptions);
      }
    } catch (error) {
      console.error("Error fetching travel type data", error);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [travelModes, setTravelModes] = useState({});
  const getDefaultData = async () => {
    try {
      let resp = await getAllTripForm("");
      if (resp) {
        setTravelModes(getValue(resp, `data.travel_modes`, []));
        let arr = getValue(resp, `data.form_layout`, []).map((item: object) => {
          if (getValue(item, `field_name`, "") === "currency") {
            return {
              ...item,
              field_name: "currency_id",
            };
          } else {
            return {
              ...item,
            };
          }
        });
        setForm(arr);
      }
    } catch (error) {}
  };
  const getData = async () => {
    if (getValue(params, `id`, "")) {
      try {
        setIsLoading(true);
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await getAllTripFormEdit(getValue(params, `id`, ""));
        } else {
          resp = await getAllTripForm("");
        }
        if (resp) {
          if (getValue(params, `id`, "")) {
            setForm(getValue(resp, `data.form_layout`, []));
            let length = getValue(resp, `data.form_layout.length`, 0);
            let array = getValue(resp, `data.form_layout`, []).filter(
              (item: object) => getValue(item, `is_visible`, false)
            );
            let payload = {
              currency_code: getValue(resp, `data.trip.claimed_currency`, ""),
            };
            let queryRequest = QueryRequestHelper(payload);
            let trip = getValue(resp, `data.trip`, {});
            getInfo(trip);
            if (getValue(resp, `data.trip.claimed_currency`, "")) {
              let currency = await getAllCurrency(queryRequest);
              if (currency) {
                trip["currency"] = {
                  id: getValue(currency, `data.data[${0}].id`, ""),
                };
              }
            }
            for (let i = 0; i < length; i++) {
              FormAppendDataRequestHelper(array[i], trip);
            }
            setForm(array);
          } else {
            setForm(getValue(resp, `data.form_layout`, []));
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };
  const getInfo = async (resp: any) => {
    const {
      flightTrips,
      flightItineraries,
      hotelItineraries,
      carItineraries,
      trainItineraries,
      busItineraries,
    } = generatePrefillTripRequest(resp);
    setRequest({
      ...request,
      air_travel_type: getValue(flightTrips, `[${0}].air_travel_type`, ""),
      class: getValue(flightTrips, `[${0}].flight.class`, ""),
      seat_type: getValue(flightTrips, `[${0}].flight.seat_type`, ""),
      meal_type: getValue(flightTrips, `[${0}].flight.meal_type`, ""),
    });
    setItenaries(flightItineraries);
    setHotelItenaries(hotelItineraries);
    setCarItenaries(carItineraries);
    setTrainItenaries(trainItineraries);
    setBusItenaries(busItineraries);
  };

  /* -------------------------------------------------------------------------- */
  /*                         Travel Document section                            */
  /* -------------------------------------------------------------------------- */
  // const [travelDoucmentsUser, setTravelDocumentsUser] = useState([]);
  const [travelDocumentsUser, setTravelDocumentsUser] =
    useState<TravelDocumentsUser | null>(null);
  const getTravelDocumentUser = async (id: string) => {
    try {
      let payload = {
        country_id: String(id),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTravelDocumentsUser(queryRequest);
      if (resp) {
        setTravelDocumentsUser(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Submit Section                               */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const [checkMandatoryModules, setCheckMandatoryModules] = useState({});
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      // setCheckMandatoryModules(checkMandatoryFields());
      const mandatoryFields = checkMandatoryFields();
      setCheckMandatoryModules({});
      setTimeout(() => {
        setCheckMandatoryModules(mandatoryFields);
      }, 0);

      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp;
        let form_payload = FormRequestHelper(form);
        let flight = generateFlightCreatePayload(
          getValue(request, `air_travel_type`, ""),
          itineraries,
          request
        );
        let hotel = generateHotelPayload(hotelItenaries);
        let car = generateCarPayload(carItenaries, params);
        let train = generateTrainPayload(trainItenaries, params);
        let bus = generateBusPayload(busItenaries, params);
        let array = flight.concat(hotel, car, train, bus);
        let payload = {
          ...form_payload,
          trip_document_id: getValue(documents, `${0}.id`, ""),
          itineraries: removeEmptyProperties(array),
        };
        if (getValue(params, `id`, "")) {
          resp = await updateTrip(
            getValue(params, `id`, ""),
            removeNullInObject(payload)
          );
        } else {
          resp = await createTrip(removeNullInObject(payload));
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          navigate(
            getValue(params, `id`, "")
              ? `/client/trips/detail/${getValue(params, `id`, "")}`
              : `/client/trips/`
          );
          hideValidation();
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };


  const [tripLoading, setTripLoading] = useState(false);
  const handleCreateTrip = async () => {
    try {
      let form_payload = FormRequestHelper(form);
      let flight = generateFlightCreatePayload(
        getValue(request, `air_travel_type`, ""),
        itineraries,
        request
      );
      let hotel = generateHotelPayload(hotelItenaries);
      let car = generateCarPayload(carItenaries, params);
      let train = generateTrainPayload(trainItenaries, params);
      let bus = generateBusPayload(busItenaries, params);
      let array = flight.concat(hotel, car, train, bus);
      let payload = {
        ...form_payload,
        itineraries: removeEmptyProperties(array),
        trip_document_id: getValue(documents, `${0}.id`, ""),
      };

      let resp;
      setTripLoading(true);

      if (getValue(params, `id`, "")) {
        resp = await updateTrip(
          getValue(params, `id`, ""),
          removeNullInObject(payload)
        );
      } else {
        resp = await createTrip(removeNullInObject(payload));
      }
      if (resp) {
        handleSubmitReport(getValue(resp, `data.id`, ""));
        hideValidation();
        setTripLoading(false);
      } else {
        setTripLoading(false);
        setIsOpen(false);
      }
    } catch (error) {
      setTripLoading(false);
    }
  };
  const hideValidation = () => {
    simpleValidator.current.fields = {};
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  /* --------------------------  Report Submission section  ----------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const [isWorkflowApprover, setIsWorkflowApprover] = useState(false);
  const [trip_id, setTrip_id] = useState("");
  const handleSubmitReport = async (id: string) => {
    try {
      setTrip_id(id);
      let resp = await getTripValidateApproverFlowType(id);
      if (resp) {
        setIsWorkflowApprover(getValue(resp, `data.isWorkflowApprover`, ""));
        if (getValue(resp, `data.isWorkflowApprover`, "")) {
          handleSubmitTrip(id);
        } else {
          handleModal();
        }
      }
    } catch (error) {}
  };
  const [submitTripLoading, setSubmitTripLoading] = useState(false);
  const [approverId, setApproverId] = useState("");
  const handleSubmitTrip = async (id: string) => {
    try {
      let requestPayload: any;
      if (isWorkflowApprover) {
        requestPayload = {
          id: id ? id : trip_id,
        };
      } else {
        requestPayload = {
          approver_id: approverId,
          id: id ? id : trip_id,
        };
      }
      setSubmitTripLoading(true);
      let resp = await submitTrip(
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        // if (!isWorkflowApprover) {
        //   handleModal();
        // }
        setIsOpen(false);
        setSubmitTripLoading(false);
        navigate(
          getValue(params, `id`, "")
            ? `/client/trips/detail/${getValue(params, `id`, "")}`
            : `/client/trips/`
        );
      } else {
        setSubmitTripLoading(false);
      }
    } catch (error) {
      setSubmitTripLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           Confirm Submit section                           */
  /* -------------------------------------------------------------------------- */
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const handleConfirmModal = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      // setCheckMandatoryModules(checkMandatoryFields());
      const mandatoryFields = checkMandatoryFields();
      setCheckMandatoryModules({});
      setTimeout(() => {
        setCheckMandatoryModules(mandatoryFields);
      }, 0);

      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsConfirmOpen(!isConfirmOpen);
    }
  };
  const handleConfirmSubmit = () => {
    handleCreateTrip();
    handleConfirmModal();
  };

  const [activeTab, setActiveTab] = useState("info");

  function removeDuplicates(data: any) {
    if (getValue(data, `length`, 0) > 0) {
      // Identify all keys that have empty string values in any object
      let emptyStringKeys: any = new Set();
      data.forEach((obj: any) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key] === "" || !obj[key]) {
            emptyStringKeys.add(key);
          }
        });
      });
      emptyStringKeys = Array.from(emptyStringKeys);
      // Convert each object to a JSON string to make them comparable and remove duplicates
      const uniqueData = new Set(data.map((item: any) => JSON.stringify(item)));
      // Convert the JSON strings back to objects
      const uniqueArray = Array.from(uniqueData).map((item: any) =>
        JSON.parse(item)
      );
      // Return the keys with empty string values if the array has objects, otherwise return an empty array
      if (uniqueArray.length > 0) {
        return emptyStringKeys;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  const checkMandatoryFields = () => {
    const infoFields =
      getValue(form, `length`, 0) > 0
        ? form.filter((item: object) => getValue(item, `is_mandatory`, false))
        : [];

    const infoObjKeys =
      getValue(infoFields, `length`, 0) > 0
        ? infoFields.map((item: object) => getValue(item, `field_name`, ""))
        : [];

    const flightObjKeys = [
      "departure_airport_code",
      "departure_date",
      "arrival_airport_code",
      // "arrival_date",
      // "description",
      // "time_preference",
      // "arrival_airport_name",
      // "departure_airport_name",
      "departure_time_preference",
      "departure_class",
      // "arrival_class",
    ];

    const flightRoundTripObjKeys = [
      "arrival_airport_code",
      "arrival_date",
      "arrival_class",
      "arrival_time_preference",
      "departure_airport_code",
      "departure_date",
      "departure_class",
      "departure_time_preference",
    ];

    const flightMultiCityObjKeys = [
      "arrival_airport_code",
      "departure_airport_code",
      "departure_date",
      "departure_class",
      "departure_time_preference",
    ];

    const hotelObjKeys = [
      "checkin_date",
      "checkin_time",
      "checkout_date",
      "checkout_time",
      "city",
      // "city_name",
    ];
    const carObjKeys = [
      "pick_up_location",
      "pick_up_date",
      "pick_up_time",
      "drop_off_location",
      "drop_off_date",
      "drop_off_time",
      "car_type",
      "is_driver_needed",
    ];
    const trainObjKeys = [
      "departure_date",
      "departure_city",
      "departure_city_name",
      "arrival_city",
      "arrival_city_name",
    ];
    const busObjKeys = [
      "departure_date",
      "departure_city",
      "departure_city_name",
      "arrival_city",
      "arrival_city_name",
    ];
    const infoFieldsMain = infoFields.map((item: any) => ({
      [item["field_name"]]: item["field_value"],
    }));
    const currentinfoObjKeys = removeDuplicates(infoFieldsMain);
    const currentflightObjKeys = removeDuplicates(itineraries);
    const currenthotelObjKeys = removeDuplicates(hotelItenaries);
    const currentcarObjKeys = removeDuplicates(carItenaries);
    const currenttrainObjKeys = removeDuplicates(trainItenaries);
    const currentbusObjKeys = removeDuplicates(busItenaries);

    const matchedInfo = currentinfoObjKeys.filter((item: string) =>
      infoObjKeys.includes(item)
    );

    const airTravelType = getValue(request, `air_travel_type`, "");
    const matchedFlight = (() => {
      switch (airTravelType) {
        case "one_way":
          return currentflightObjKeys.filter((item: string) =>
            flightObjKeys.includes(item)
          );
        case "round_trip":
          return currentflightObjKeys.filter((item: string) =>
            flightRoundTripObjKeys.includes(item)
          );
        default:
          return currentflightObjKeys.filter((item: string) =>
            flightMultiCityObjKeys.includes(item)
          );
      }
    })();

    const matchedHotel = currenthotelObjKeys.filter((item: string) =>
      hotelObjKeys.includes(item)
    );

    const matchedCar = currentcarObjKeys.filter((item: string) =>
      carObjKeys.includes(item)
    );
    const matchedTrain = currenttrainObjKeys.filter((item: string) =>
      trainObjKeys.includes(item)
    );
    const matchedBus = currentbusObjKeys.filter((item: string) =>
      busObjKeys.includes(item)
    );

    return {
      info: matchedInfo,
      flight: matchedFlight,
      hotel: matchedHotel,
      car: matchedCar,
      train: matchedTrain,
      bus: matchedBus,
    };
  };

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          e = null;
        } else {
          e = null;
        }
      } catch (error) {
        e = null;
      }
    });
  };

  const handleRemoveDocuments = (index: number) => {
    let list = documents.filter((_item: object, i: number) => index !== i);
    setDocuments(list);
  };


  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("trips") ? (
        <NoAccessPage />
      ) : (
        <>
          <CreateTripsStickyHeader
            route={
              getValue(params, `id`, "")
                ? `/client/trips/detail/${getValue(params, `id`, "")}`
                : `/client/trips/`
            }
            submitLoading={submitLoading}
            handleSubmit={handleSubmit}
            handleCreateTrip={handleConfirmModal}
            tripLoading={tripLoading}
            id={getValue(params, `id`, "")}
            permissions={getValue(props, `permissions`, [])}
          />
          <TripsTabNavigation
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            flightList={itineraries}
            hotelList={hotelItenaries}
            carList={carItenaries}
            trainList={trainItenaries}
            busList={busItenaries}
            request={request}
            travelModes={travelModes}
            checkMandatoryModules={checkMandatoryModules}
          />
          <div className="trips_main_section">
            <div className="trips_flights_cotnainer">
              {activeTab === "info" && (
                <>
                  <div className="form_flex_container">
                    <div className="form_trips_wrapper">
                      {getValue(form, `length`, 0) > 0
                        ? form.map((item: any, index: number) => {
                            let travel_type = form.filter(
                              (item: object) =>
                                getValue(item, `field_name`, "") ===
                                "travel_type"
                            );
                            if (
                              getValue(travel_type, `length`, 0) > 0 &&
                              (getValue(
                                travel_type,
                                `[${0}].field_value`,
                                ""
                              ) === "domestic"
                                ? getValue(item, `field_name`, false) !==
                                    "destination_country" &&
                                  getValue(item, `field_name`, false) !==
                                    "is_visa_required"
                                : getValue(item, `field_name`, false))
                            )
                              if (getValue(item, `is_visible`, false))
                                // getValue(travel_type, `[${0}].field_value`, "") ===
                                // "domestic"
                                //   ? alert("true")
                                //   : alert("false");

                                return (
                                  <div className="form_trips_wrapper_container">
                                    <RenderForm
                                      item={item}
                                      setFields={setForm}
                                      index={index}
                                      simpleValidator={simpleValidator}
                                      tripLimit={tripLimit}
                                    />
                                  </div>
                                );
                          })
                        : null}

                      {getValue(form, `length`, 0) > 0
                        ? form.map((item: any, index: number) => {
                            let travel_type = form.filter(
                              (item: object) =>
                                getValue(item, `field_name`, "") ===
                                "travel_type"
                            );
                            const getDestinationCountryValue = (form: any) => {
                              const destinationField = form.find(
                                (item_form: any) =>
                                  item_form.field_name === "destination_country"
                              );
                              return destinationField?.field_value || "";
                            };

                            const destinationValue =
                              getDestinationCountryValue(form);
                            if (
                              getValue(
                                travel_type,
                                `[${0}].field_value`,
                                ""
                              ) === "international"
                            )
                              return (
                                <>
                                  {item.field_name === "travel_type" &&
                                    item.field_value === "international" &&
                                    destinationValue && (
                                      <div className="international-docs d-flex gap-3 w-100">
                                        {/* {travelDoucmentsUser} */}
                                        {travelDocumentsUser ? (
                                          <>
                                            {travelDocumentsUser?.passport
                                              .length > 0 ? (
                                              <div className="card p-2 w-50 passport-card-wrap">
                                                <b className="mb-2 border-bottom pb-2">
                                                  Passport
                                                </b>
                                                {travelDocumentsUser.passport.map(
                                                  (passport, index) => (
                                                    <>
                                                      <div className="passport-section-holder">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                          <div className="doc-name mb-2">
                                                            <label>
                                                              Name of Applicant
                                                            </label>
                                                            <div className="value-holder">
                                                              {formatText(
                                                                getValue(
                                                                  passport,
                                                                  `user.first_name`,
                                                                  ""
                                                                )
                                                              )}{" "}
                                                              {""}
                                                              {formatText(
                                                                getValue(
                                                                  passport,
                                                                  `user.middle_name`,
                                                                  ""
                                                                )
                                                              )}{" "}
                                                              {""}
                                                              {formatText(
                                                                getValue(
                                                                  passport,
                                                                  `user.last_name`,
                                                                  ""
                                                                )
                                                              )}
                                                            </div>
                                                          </div>

                                                          <div className="conent-wrap">
                                                            <label>
                                                              Document No :
                                                            </label>
                                                            <div>
                                                              <div className="value-holder">
                                                                {getValue(
                                                                  passport,
                                                                  `document_number`,
                                                                  ""
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="d-flex align-items-center gap-4">
                                                          <div className="conent-wrap">
                                                            <label>
                                                              Valid From :
                                                            </label>
                                                            <div>
                                                              <div className="value-holder">
                                                                {convertDateAsString(
                                                                  getValue(
                                                                    passport,
                                                                    `document_valid_from`,
                                                                    ""
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="conent-wrap">
                                                            <label>
                                                              Valid Till :
                                                            </label>
                                                            <div>
                                                              <div className="value-holder">
                                                                {convertDateAsString(
                                                                  getValue(
                                                                    passport,
                                                                    `document_expires_on`,
                                                                    ""
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <div className="card p-2 w-50 passport-card-wrap">
                                                <b className="mb-2 border-bottom pb-2">
                                                  Passport
                                                </b>

                                                <div className="d-flex align-items-center justify-content-between">
                                                  <p>Passport not found</p>

                                                  <NavLink
                                                    to={`/client/settings/travel-documents`}
                                                    target="_blank"
                                                  >
                                                    {/* <button className="ascent-button ascent-button--header-buttons ascent-button--primary"> */}
                                                    Add Passport
                                                    {/* </button> */}
                                                  </NavLink>
                                                </div>
                                              </div>
                                            )}

                                            {travelDocumentsUser.visa.length >
                                            0 ? (
                                              <div className="card p-2 w-50 passport-card-wrap">
                                                <b className="mb-2 border-bottom pb-2">
                                                  Visa
                                                </b>
                                                {travelDocumentsUser.visa.map(
                                                  (visa, index) => (
                                                    <>
                                                      <div className="passport-section-holder">
                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                          <div className="doc-name ">
                                                            <label>
                                                              Name of Applicant
                                                            </label>
                                                            <div className="value-holder">
                                                              {formatText(
                                                                getValue(
                                                                  visa,
                                                                  `user.first_name`,
                                                                  ""
                                                                )
                                                              )}{" "}
                                                              {""}
                                                              {formatText(
                                                                getValue(
                                                                  visa,
                                                                  `user.middle_name`,
                                                                  ""
                                                                )
                                                              )}{" "}
                                                              {""}
                                                              {formatText(
                                                                getValue(
                                                                  visa,
                                                                  `user.last_name`,
                                                                  ""
                                                                )
                                                              )}
                                                            </div>
                                                          </div>

                                                          <div className="conent-wrap">
                                                            <label>
                                                              Document No :
                                                            </label>
                                                            <div>
                                                              <div className="value-holder">
                                                                {getValue(
                                                                  visa,
                                                                  `document_number`,
                                                                  ""
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="d-flex align-items-center gap-4 mb-2">
                                                          <div className="conent-wrap">
                                                            <label>
                                                              Valid From :
                                                            </label>
                                                            <div>
                                                              <div className="value-holder">
                                                                {convertDateAsString(
                                                                  getValue(
                                                                    visa,
                                                                    `document_valid_from`,
                                                                    ""
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="conent-wrap">
                                                            <label>
                                                              Valid Till :
                                                            </label>
                                                            <div>
                                                              <div className="value-holder">
                                                                {convertDateAsString(
                                                                  getValue(
                                                                    visa,
                                                                    `document_expires_on`,
                                                                    ""
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="d-flex align-items-center gap-4">
                                                          <div className="conent-wrap">
                                                            <label>
                                                              Issuing Country :
                                                            </label>
                                                            <div>
                                                              <div className="value-holder">
                                                                {formatText(
                                                                  getValue(
                                                                    visa,
                                                                    `document_country.name`,
                                                                    ""
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <div className="card p-2 w-50 passport-card-wrap">
                                                <b className="mb-2 border-bottom pb-2">
                                                  Visa
                                                </b>

                                                <div className="d-flex align-items-center justify-content-between">
                                                  <p>Visa not found</p>

                                                  <NavLink
                                                    to={`/client/settings/travel-documents`}
                                                    target="_blank"
                                                  >
                                                    {/* <button className="ascent-button ascent-button--header-buttons ascent-button--primary"> */}
                                                    Add Visa
                                                    {/* </button> */}
                                                  </NavLink>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <p>Loading travel documents...</p>
                                        )}
                                      </div>
                                    )}
                                </>
                              );
                          })
                        : null}
                    </div>
                  </div>

                  <div style={{ width: "70%", margin: "0 auto" }}>
                    <div className="trip-upload-doc">
                      <DragAndDropFileComponent
                        uploadFile={handleUploadDocuments}
                        name="file"
                        types={fileTypes}
                        multiple
                        classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                        fileName={getValue(props, `importedFile.name`, "")}
                        setImportTedFile={props.setImportTedFile}
                      />

                      {getValue(documents, `length`, 0) > 0 &&
                        documents.map((item: object, index: number) => {
                          return (
                            <div className="d-flex align-items-center ">
                              <div className="p-2 border border-radius__5 d-flex align-items-center w-100 mt-2">
                                <div>
                                  <FileLogSvgComponent />
                                </div>
                                <p className="small_text__16 ms-2">
                                  {concatString(getValue(item, `name`, ""), 30)}
                                  {getValue(item, `extension`, "")}
                                </p>
                              </div>
                              <div
                                className="mx-2"
                                onClick={() => handleRemoveDocuments(index)}
                              >
                                <DeleteSvgComponent />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}

              {activeTab === "flight" &&
                getValue(travelModes, `flight_reservation`, false) && (
                  <>
                    <TripsCreateFlightList
                      request={request}
                      itineraries={itineraries}
                      setItenaries={setItenaries}
                      validator={simpleValidator}
                      setRequest={setRequest}
                      hideValidation={hideValidation}
                      travelModes={travelModes}
                      flightPreference={flightPreference}
                    />
                  </>
                )}
              {activeTab === "hotel" &&
                getValue(travelModes, `hotel_reservation`, false) && (
                  <>
                    <TripCreateHotelSection
                      hotelItenaries={hotelItenaries}
                      setHotelItenaries={setHotelItenaries}
                      validator={simpleValidator}
                      hideValidation={hideValidation}
                    />
                  </>
                )}

              {activeTab === "car" &&
                getValue(travelModes, `car_rental`, false) && (
                  <>
                    <TripCreateCarSection
                      itenaries={carItenaries}
                      setItenaries={setCarItenaries}
                      validator={simpleValidator}
                      hideValidation={hideValidation}
                    />
                  </>
                )}
              {activeTab === "train" &&
                getValue(travelModes, `train`, false) && (
                  <>
                    <TripCreateTrainSection
                      itenaries={trainItenaries}
                      setItenaries={setTrainItenaries}
                      validator={simpleValidator}
                      hideValidation={hideValidation}
                    />
                  </>
                )}
              {activeTab === "bus" && getValue(travelModes, `bus`, false) && (
                <>
                  <TripCreateBusSection
                    itenaries={busItenaries}
                    setItenaries={setBusItenaries}
                    validator={simpleValidator}
                    hideValidation={hideValidation}
                  />
                </>
              )}
            </div>
          </div>
          <ReportSubmitModal
            isOpen={isOpen}
            handleModal={handleModal}
            handleSubmit={handleSubmitTrip}
            approverId={approverId}
            setApproverId={setApproverId}
            submitLoading={submitTripLoading}
          />
          <SubmitConfirmModal
            isOpen={isConfirmOpen}
            handleModal={handleConfirmModal}
            handleSubmit={handleConfirmSubmit}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientTripsCreate;
