import { QueryRequestHelper } from "@common/query-request-helper";
import Notfound from "@components/Not-Found";
import GearLoader from "@components/common/GearLoader";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import {
  deleteCurrency,
  getAllCurrency,
  updateCurrencyStatusBulk,
} from "@services/currency.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import "./currency.scss";
import Switch from "@components/common/switch";
import { NavLink } from "react-router-dom";
import DeleteModal from "@common/popup/DeleteModal";
import { toast } from "react-toastify";
import NavigationBar from "@components/NotificationBar";
import PaginationPage from "@components/pagination/pagination";
import { useNavigate } from "react-router-dom";
import CurrencyTable from "./table";
import NoAccessPage from "@components/common/NoAccess";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";
import { DropdownItem } from "reactstrap";
import ImportPopup from "@components/BulkUploadDropdown/importPopup";
import CommonDownloadSection from "@components/Download";

function AdminOrganisationCurrencies(props: any) {
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [currencyData, setCurrencyData] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    //  const contentState = convertFromRaw(content);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      // if (params.search_text) {
      //   setSearchInput(params.search_text);
      //   setShowSearchTextbox(true);
      // }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      setIsLoading(true);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let payload: any = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        page_size: params.limit ? parseInt(params.limit) : limit,
        search_text: params.search_text,
        // status: params.status,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllCurrency(queryRequest);
      if (resp) {
        let list =
          getValue(resp, `data.data.length`, 0) > 0
            ? getValue(resp, `data.data`, []).map((item: object) => ({
                ...item,
                status: getValue(item, `is_active`, false)
                  ? "Active"
                  : "Inactive",
              }))
            : [];
        setCurrencyData(list);
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const onChange = () => {};
  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteCurrency(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(currencyData, `length`, 0) > 0
          ? currencyData.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  const handleChangeActiveStatus = async (id: string, status: boolean) => {
    try {
      let resp = await updateCurrencyStatusBulk({
        ids: [id],
        is_active: status,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      } else {
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);

    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const [activeExchange, setActiveExchange] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isBulkUploadPopupVisible, setBulkuploadPopupVisibility] = useState(
    false
  );

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_organisations_currencies"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
            <h4 className="dashbaord-wrapper__header-title">Currencies</h4>
            <div></div>
          </div>
          {getValue(selectedIDs, `length`, 0) > 0 && (
            <NavigationBar
              selectedIDs={selectedIDs}
              handleChangeActiveStatus={handleChangeActiveStatus}
              multi={
                getValue(props, `permissions`, []).includes("update")
                  ? true
                  : false
              }
              permissions={getValue(props, `permissions`, [])}
            />
          )}
          <div className="currency_container">
            <div className="currency_header">
              <div></div>
              {getValue(props, `permissions`, []).includes("create") && (
                <div className="d-flex mx-5">
                  {/* <div className="currency_header_exchange">
                    <p className="currency_header_p">
                      Disable Automatic Exchange Rate
                    </p>
                    <Switch is_active={activeExchange} onChange={()=>setActiveExchange(!activeExchange)} />

                    {activeExchange && (
                      <>
                        <div className="dd-enabled-exchange-rates">
                          <div
                            onClick={() => {
                              setSelectedOption("EXCHANGE_CURRENCY_IMPORT");
                              setBulkuploadPopupVisibility(true);
                              setSelectedUrl("https://asccuriodata.s3.ap-south-1.amazonaws.com/tmp/9dbfb094-baea-4274-b606-98b2e74a388b.xlsx")
                            }}
                            className="p-3 border-bottom"
                          >
                            Import
                          </div>

                        <NavLink className="p-3 text-primary d-block"  
                          to={`/admin/organisation/currencies/exchange-rate`}>
                          Go to Exchange Rate
                        </NavLink>
                        </div>
                      </>
                    )}
                  </div> */}
                  <NavLink to={`/admin/organisation/currency/add`}>
                    <div className="currency_header_add">
                      <img
                        src="/images/add_blue.svg"
                        className="currency_header_img"
                      />
                      <p className="currency_header_p">New Currency</p>
                    </div>
                  </NavLink>
                </div>
              )}
            </div>
            
            <div className="currency_table_container">
              {isLoading || !props.permissionAPITriggered ? (
                // <GearLoader />
                <SkeletonTable />
              ) : getValue(currencyData, `length`, 0) > 0 ? (
                <CurrencyTable
                  currencyData={currencyData}
                  handleDeleteFun={handleDeleteFun}
                  handleChangeActiveStatus={handleChangeActiveStatus}
                  permissions={getValue(props, `permissions`, [])}
                />
              ) : (
                <Notfound />
              )}
            </div>
          </div>
          <div className="fixed_footer_wrapper d-flex justify-content-between">
            {currencyData.length > 0 && (
              <img
                src="/images/building-illustration.svg"
                className="img-fluid"
              />
            )}
            {totalCount > 10 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              <p className="show-rows-count mt-3 d-flex">
                Total Count:&nbsp;&nbsp;{" "}
                <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
              </p>
            )}
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />

        <ImportPopup
          url={selectedUrl}
          type={selectedOption}
          setBulkuploadPopupVisibility={setBulkuploadPopupVisibility}
          isBulkUploadPopupVisible={isBulkUploadPopupVisible}
          setSelectedOption={setSelectedOption}
        />
        </>
      )}
    </DashboardLayout>
  );
}

export default AdminOrganisationCurrencies;
