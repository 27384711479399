import { getValue } from "@utils/lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24.28px",
  borderWidth: "2px",
  borderRadius: '4px',
  borderColor: "#ececf9",
  borderStyle: "dashed",
  backgroundColor: "#F7FBFF",
  color: "#bdbdbd",
  height: "166px",
  justifyContent: "center",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#adff2f",
};

const rejectStyle = {
  borderColor: "red",
};

function DragAndDropFileComponent(props) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    props.uploadFile(acceptedFiles);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = acceptedFiles.map((file) => (
    <div key={file.name}>
      <p>{file.name} </p>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });
  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <section className="cursor-pointer expense-add-custom-inputfile">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className="custom-inputfile">
          <img
            src="/images/nofiles.png"
            className="img-fluid"
            height={52.88}
            width={47.54}
          />
          {props.proofText ? <p className="custom-inputfile__text">Attach Proof</p> : <>
          <p className="custom-inputfile__text">Drag & Drop Files here</p>
          <p className="custom-inputfile__text">OR</p>
          <a href="#0">Browse Files</a> </> }
        </div>
        <p style={{ color: "red" }}> {fileRejectionItems} </p>
      </div>
    </section>
  );
}

export default DragAndDropFileComponent;
