import { config } from "../env";
import { get, post, postFileUpload } from "./helpers/http-handler";

//common
export const getTemplates = (queryRequest: string) =>
  get(`${config.API_URL}/templates?${queryRequest}`);

export const getImports = (queryRequest: string) =>
  get(`${config.API_URL}/imports?${queryRequest}`);

export const getReportImports = (queryRequest: string) =>
  get(`${config.API_URL}/reports?${queryRequest}`);

// ---------------- Users ----------------- //
export const importUsers = (payload: object) =>
  postFileUpload(`${config.API_URL}/users/import`, payload);

export const exportUsers = (queryRequest: string) =>
  get(`${config.API_URL}/users/export?${queryRequest}`);

// ---------------- Reports ----------------- //

export const importExpenseReports = (payload: object) =>
  postFileUpload(
    `${config.API_URL}/expense-report/admin/bulk-reimbursement`,
    payload
  );

// ---------------- departments ----------------- //

export const importDepartments = (payload: object) =>
  postFileUpload(`${config.API_URL}/departments/import`, payload);

export const exportDepartments = (queryRequest: string) =>
  get(`${config.API_URL}/departments/export?${queryRequest}`);

// ---------------- projects ----------------- //

export const importProjects = (payload: object) =>
  postFileUpload(`${config.API_URL}/project/import`, payload);

export const exportProjects = (queryRequest: string) =>
  get(`${config.API_URL}/project/export?${queryRequest}`);

// ---------------- expense-category ----------------- //

export const importExpenseCategory = (payload: object) =>
  postFileUpload(`${config.API_URL}/expense-category/import`, payload);

export const exportExpenseCategory = (queryRequest: string) =>
  get(`${config.API_URL}/expense-category/export?${queryRequest}`);

// ---------------- expense-type ----------------- //

export const importExpenseType = (payload: object) =>
  postFileUpload(`${config.API_URL}/expense-type/import`, payload);

export const exportExpenseType = (queryRequest: string) =>
  get(`${config.API_URL}/expense-type/export?${queryRequest}`);

// ---------------- list-item ----------------- //

export const importListItem = (payload: object) =>
  postFileUpload(`${config.API_URL}/list-management/list/import`, payload);

// ---------------- Currency Exchange ----------------- //

export const importExchangeRatesCurrency = (payload: object) =>
  postFileUpload(`${config.API_URL}/exchange-rates/import`, payload);

export const exportExchangeRatesCurrency = (queryRequest: string) =>
  get(`${config.API_URL}/exchange-rates/export?${queryRequest}`);