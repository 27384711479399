import { handleDownloadOnClick } from "@common/downloadfile";
import { QueryRequestHelper } from "@common/query-request-helper";
import CommonImportDataPopup from "./BulkImportPopup";
import {
  exportUsers,
  getTemplates,
  importDepartments,
  importExchangeRatesCurrency,
  importExpenseCategory,
  importExpenseReports,
  importExpenseType,
  importListItem,
  importProjects,
  importUsers,
} from "@services/import.service";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { toast } from "react-toastify";

function ImportPopup(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const { isBulkUploadPopupVisible, setBulkuploadPopupVisibility } = props;
  const [isDataImported, setIsDataImported] = useState(false);
  const [response, setResponse] = useState({});
  const [importType, setImportType] = useState("");
  const [importedFile, setImportTedFile] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleUpload = async () => {
    if (importedFile) {
      try {
        setSubmitLoading(true);
        let formData = new FormData();
        formData.append("file", importedFile);
        let resp;
        if (getValue(props, `type`, "")) {
          let commonFormData = new FormData();
          commonFormData.append("file", importedFile);
          commonFormData.append("type", getValue(props, `type`, ""));
          //---------------------- Users ----------------------//
          if (getValue(props, `type`, "") === "USER") {
            resp = await importUsers(commonFormData);
          }
          //---------------------- Users ----------------------//
          if (getValue(props, `type`, "") === "EXPENSE_REPORT") {
            let reportFormData = new FormData();
            reportFormData.append("file", importedFile);
            resp = await importExpenseReports(reportFormData);
          }
          //---------------------- Department ----------------------//
          if (getValue(props, `type`, "") === "DEPARTMENT") {
            let reportFormData = new FormData();
            reportFormData.append("file", importedFile);
            resp = await importDepartments(reportFormData);
          }

          //---------------------- Project ----------------------//
          if (getValue(props, `type`, "") === "PROJECT") {
            let reportFormData = new FormData();
            reportFormData.append("file", importedFile);
            resp = await importProjects(reportFormData);
          }

          //---------------------- Expense Type ----------------------//
          if (getValue(props, `type`, "") === "EXPENSE_TYPE") {
            let reportFormData = new FormData();
            reportFormData.append("file", importedFile);
            resp = await importExpenseType(reportFormData);
          }
          //---------------------- Expense Category ----------------------//
          if (getValue(props, `type`, "") === "EXPENSE_CATEGORY") {
            let reportFormData = new FormData();
            reportFormData.append("file", importedFile);
            resp = await importExpenseCategory(reportFormData);
          }
          //---------------------- Expense Category ----------------------//
          if (getValue(props, `type`, "") === "LIST_ITEM") {
            let reportFormData = new FormData();
            reportFormData.append("file", importedFile);
            resp = await importListItem(reportFormData);
          }
          //---------------------- Expense Type ----------------------//
          if (getValue(props, `type`, "") === "EXCHANGE_CURRENCY_IMPORT") {
            let reportFormData = new FormData();
            reportFormData.append("file", importedFile);
            resp = await importExchangeRatesCurrency(reportFormData);
          }
        } else {
          toast.error("Something went wrong, Please try after sometime");
        }
        if (resp) {
          setSubmitLoading(false);
          toast.success(getValue(resp, `message`, ""));
          setImportType("");
          setImportTedFile(null);
          importedSuccessfully();
          setResponse(getValue(resp, `data`, {}));
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    } else {
      toast.error("Please Upload File");
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeUpload = async (e: any) => {
    // setImportTedFile(e.target.files[0]);
    setImportTedFile(e[0]);
  };
  const closePopup = () => {
    setBulkuploadPopupVisibility(false);
    setIsDataImported(false);
    setImportTedFile("");
    props.setSelectedOption("");
  };
  const importedSuccessfully = () => {
    setIsDataImported(!isDataImported);
  };
  const handleDownload = async (type: string) => {
    let payload: any = {
      type: type,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getTemplates(queryRequest);
      if (resp) {
        let URL = getValue(resp, `data[${0}].url`, "");
        let type = getValue(resp, `data[${0}].type`, "");

        if (URL) {
          handleDownloadOnClick(URL, type);
        }
      }
    } catch (error) {}
    // var link = document.createElement("a");
    // link.target = "_blank";
    // link.download = "Contact";
    // link.href = "url here";
    // link.click();
  };

  return (
    <div>
      {isBulkUploadPopupVisible && (
        <CommonImportDataPopup
          handleUpload={handleUpload}
          handleChangeUpload={handleChangeUpload}
          closePopup={closePopup}
          isDataImported={isDataImported}
          setIsDataImported={setIsDataImported}
          importedSuccessfully={importedSuccessfully}
          importedFile={importedFile}
          setImportTedFile={setImportTedFile}
          response={response}
          handleDownload={handleDownload}
          submitLoading={submitLoading}
          url={props.url}
          type={props.type}
        />
      )}
    </div>
  );
}

export default ImportPopup;
