import InputField from "@common/InputRuleFeild";
import { QueryRequestHelper } from "@common/query-request-helper";
import FormBuilder from "@components/common/FormBuilder";
import { getAllCurrency } from "@services/currency.service";
import { getValue } from "@utils/lodash";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import { ReactMultiEmail } from "react-multi-email";
import React from "react";
import { NewDate, convertDate } from "@common/date-helpers";
import {
  adminTriggerForrex,
  adminTriggerForrexUploadDoc,
  deleteAdminTriggerForrexUploadDoc,
  getSpecificForex,
} from "@services/trips.service";
import { toast } from "react-toastify";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import { mediaUpload } from "@services/upload.service";
import FileLogSvgComponent from "@assets/svg-components/file-log";
import { concatString } from "@common/text-helpers";
import DeleteSvgComponent from "@assets/svg-components/delete";
import DeleteModal from "@common/popup/DeleteModal";

function ForexPopup(props: any) {
  const { showForexPopup, setShowForexPopup, submitLoading, editId, forexId } =
    props;

  const [documents, setDocuments] = useState<any>([]);

  useEffect(() => {
    getCurrencyList();
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (props.forexId) {
      getForexData();
    }
  }, [props.permissionAPITriggered, props.forexId]);


  // useEffect(()=> {
  //   const updateEditDocuments = async () => {
  //     if(getValue(props, `forexId`, "")) {
  //       try {
  //         let uploadDocPayload = {
  //           document_ids: documents.map((doc:any) => getValue(doc, `id`,""))
  //         }
  //         let resp = await adminTriggerForrexUploadDoc(getValue(props, `forexId`, ""),uploadDocPayload)

  //         if(resp){
  //           toast.success(getValue(resp, `message`, ""));
  //         }

  //       } catch (error) {

  //       }
  //     }
  //   }
  //   updateEditDocuments();
  // },[documents])

  const [request, setRequest] = useState<any>({
    currency: "",
    per_diem_amount_to_cash: "",
    per_diem_amount_in_card: "",
    local_commute_amount_in_card: "",
    accommodation_amount_loaded_to_card: "",
    airport_transfer_amount_in_card: "",
    total_forex: "",
    date_of_forex_delivery: "",
    country_name: "",
    to_emails: [],
    cc_emails: [],
    document_ids: [],
  });

  // Calculate total forex whenever any amount changes
  useEffect(() => {
    const total = calculateTotalForex(request);
    setRequest((prev: any) => ({
      ...prev,
      total_forex: total,
    }));
  }, [
    getValue(request, `per_diem_amount_to_cash`, ""),
    getValue(request, `per_diem_amount_in_card`, ""),
    getValue(request, `local_commute_amount_in_card`, ""),
    getValue(request, `accommodation_amount_loaded_to_card`, ""),
    getValue(request, `airport_transfer_amount_in_card`, ""),
  ]);

  const calculateTotalForex = (data: any) => {
    const amounts = [
      getValue(data, `per_diem_amount_to_cash`, ""),
      getValue(data, `per_diem_amount_in_card`, ""),
      getValue(data, `local_commute_amount_in_card`, ""),
      getValue(data, `accommodation_amount_loaded_to_card`, ""),
      getValue(data, `airport_transfer_amount_in_card`, ""),
    ];

    return amounts
      .map((amount) => parseFloat(amount) || 0)
      .reduce((sum, current) => sum + current, 0)
      .toFixed(2);
  };

  const [fromDate, setFromDate] = useState<any>(null);
  const [focused, setFocused] = React.useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [currencyData, setCurrencyData] = useState([]);

  const getCurrencyList = async () => {
    try {
      setIsLoading(true);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let payload: any = {
        // page_no: params.page_no ? parseInt(params.page_no) : 1,
        search_text: params.search_text,
        // status: params.status,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllCurrency(queryRequest);
      if (resp) {
        setCurrencyData(
          getValue(resp, `data.data`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `currency_code`, ""),
            label: getValue(item, `currency_code`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getForexData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificForex(getValue(props, `forexId`, ""));
      if (resp) {
        console.log(resp, "===RESP===>");
        setRequest({
          ...request,
          currency: getValue(resp, `data.currency.id`, ""),
          per_diem_amount_to_cash: getValue(
            resp,
            `data.per_diem_amount_to_cash`,
            ""
          ),
          per_diem_amount_in_card: getValue(
            resp,
            `data.per_diem_amount_in_card`,
            ""
          ),
          local_commute_amount_in_card: getValue(
            resp,
            `data.local_commute_amount_in_card`,
            ""
          ),
          accommodation_amount_loaded_to_card: getValue(
            resp,
            `data.accommodation_amount_loaded_to_card`,
            ""
          ),
          airport_transfer_amount_in_card: getValue(
            resp,
            `data.airport_transfer_amount_in_card`,
            ""
          ),
          total_forex: getValue(resp, `data.total_forex`, ""),
          country_name: getValue(resp, `data.country_name`, ""),
          date_of_forex_delivery: setFromDate(
            new Date(getValue(resp, `data.date_of_forex_delivery`, ""))
          ),
          to_emails: getValue(resp, `data.to_emails`, []),
          cc_emails: getValue(resp, `data.cc_emails`, []),
          // document_id: (getValue(resp,``))
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "currency") {
      setRequest({
        ...request,
        currency: getValue(e, `id`, ""),
      });
    }
  };

  const [submitForexLoading, setSubmitForexLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setSubmitForexLoading(true);
      let requestPayload: any;
      requestPayload = {
        currency: getValue(request, `currency`, ""),
        per_diem_amount_to_cash: getValue(
          request,
          `per_diem_amount_to_cash`,
          ""
        ),
        per_diem_amount_in_card: getValue(
          request,
          `per_diem_amount_in_card`,
          ""
        ),
        local_commute_amount_in_card: getValue(
          request,
          `local_commute_amount_in_card`,
          ""
        ),
        accommodation_amount_loaded_to_card: getValue(
          request,
          `accommodation_amount_loaded_to_card`,
          ""
        ),
        airport_transfer_amount_in_card: getValue(
          request,
          `airport_transfer_amount_in_card`,
          ""
        ),
        total_forex: getValue(request, `total_forex`, ""),
        date_of_forex_delivery: convertDate(fromDate),
        to_emails: getValue(request, `to_emails`, []),
        cc_emails: getValue(request, `cc_emails`, []),
        document_ids: documents.map((doc: any) => getValue(doc, `id`, "")),
      };

      let resp = await adminTriggerForrex(
        getValue(props, `tripId`, ""),
        requestPayload
      );

      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitForexLoading(false);
        setShowForexPopup(false);
      }
    } catch (error) {
      setSubmitForexLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  console.log(getValue(props, `forexId`, ""), "===forexId==");
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const handleUploadDocuments = async (e: any,id:string) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        console.log(props.forexId);
        let resp = await mediaUpload(formData);
        if (resp) {
          console.log(resp);
          console.log(props);

          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          console.log(id);

          // if (props.forexId) {
          //   // try {
          //   let uploadDocPayload = {
          //     document_ids: documents.map((doc: any) =>
          //       getValue(doc, `id`, "")
          //     ),
          //   };
          //   let resp1 = await adminTriggerForrexUploadDoc(
          //     getValue(props, `forexId`, ""),
          //     getValue(resp, `data.id`, {})
          //   );
          //   if (resp1) {
          //     toast.success(getValue(resp, `message`, ""));
          //   }

          //   // } catch (error) {

          //   // }
          // }
          e = null;
        } else {
          e = null;
        }
      } catch (error) {
        e = null;
      }
    });
  };

  const [deleteIndex, setDeleteIndex] = useState<number | undefined>();

  const handleRemoveDocuments = (index: number) => {
    let list = documents.filter((_item: object, i: number) => index !== i);
    console.log(index);
    // setDocuments(list);
    console.log(documents[index], "---documents[index];--");
    handleDeleteFun(documents[index].id, documents[index].name);
    setDeleteIndex(index);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let deletePayload = {
        document_id: deleteId,
      };
      let resp = await deleteAdminTriggerForrexUploadDoc(
        getValue(props, `forexId`, ""),
        deletePayload
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        let list = documents.filter(
          (_item: object, i: number) => deleteIndex !== i
        );
        setDocuments(list);

        handleDelete();
      }
    } catch (error) {}
  };

  return (
    <>
      <div
        className={`compelte-task-wrapper create-report-popup-wrapper ${
          showForexPopup ? "compelte-task-wrapper--active" : ""
        }`}
        style={{ width: "40%" }}
      >
        <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
          <h6 className="compelte-task-wrapper__title">Request Forex</h6>
          <img
            src="/images/close-white.svg"
            className="img-fluid navigation-bar-wrapper__close-btn"
            onClick={() => {
              setShowForexPopup(false);
            }}
          />
        </div>
        <div className="unassociated_main_container forex_main_container">
          <div className="d-flex align-items-center gap-2">
            <div className="label-value-holder">
              <label className="form-label">Name</label>
              <div>
                <InputField
                  inputType="TEXT"
                  value={`${getValue(
                    props,
                    `formInfo.created_by.first_name`,
                    ""
                  )} ${getValue(props, `formInfo.created_by.last_name`, "")}`}
                  name={"Name"}
                  label={"Name"}
                  placeholder="Enter Name"
                  disabled
                />
              </div>
            </div>
            <div className="label-value-holder">
              <label className="form-label">Mobile Number</label>
              <div>
                <InputField
                  inputType="TEXT"
                  value={
                    getValue(props, "formInfo.created_by.mobile_number", "")
                      ? getValue(props, "formInfo.created_by.mobile_number", "")
                      : "-"
                  }
                  name={"Mobile Number"}
                  label={"Mobile Number"}
                  placeholder="Enter Mobile Number"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div className="label-value-holder">
              <label className="form-label">Department</label>
              <div>
                <InputField
                  inputType="TEXT"
                  value={getValue(
                    props,
                    `formInfo.created_by.department.department_name`,
                    ""
                  )}
                  name={"Department"}
                  label={"Department"}
                  placeholder="Enter Department"
                  disabled
                />
              </div>
            </div>
            <div className="label-value-holder">
              <label className="form-label">Email</label>
              <div>
                <InputField
                  inputType="TEXT"
                  value={getValue(props, `formInfo.created_by.email`, "")}
                  name={"Email"}
                  label={"Email"}
                  placeholder="Enter Email"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div className="">
              <label className="form-label">Country</label>
              <InputField
                inputType="TEXT"
                value={getValue(request, `country_name`, "")}
                name={"report_name"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    country_name: e.target.value,
                  })
                }
                label={"country_name"}
                placeholder="country_name"
                validator={simpleValidator}
              />
            </div>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div className=" w-50">
              <label className="form-label">Currency</label>
              <InputField
                inputType="INPUT_REACT_SELECT"
                placeholder="Choose Currency"
                className="form-control w-100"
                name="currency"
                id="currency"
                options={currencyData}
                value={
                  getValue(currencyData, `length`, 0) > 0
                    ? currencyData.filter(
                        (item: object) =>
                          getValue(item, `id`, "") ==
                          getValue(request, `currency`, "")
                      )[0]
                    : ""
                }
                onChange={(e: any) => handleChangeSelect(e, "currency")}
                label="currency"
                isClear
              />
            </div>
            <div className="form-group date-forex-wrap">
              <label htmlFor="" className="form-label">
                Date of Forex
              </label>
              <DatePicker
                selected={fromDate}
                onChange={(date: Date | null) => setFromDate(date)}
                dateFormat="d MMMM, yyyy"
                placeholderText="Select From Date"
                className="custom-form-control form-control"
              />
            </div>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div className="">
              <label className="form-label">Per Diem Amount to Cash</label>
              <InputField
                inputType="TEXT"
                value={getValue(request, `per_diem_amount_to_cash`, "")}
                name={"report_name"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    per_diem_amount_to_cash: e.target.value,
                  })
                }
                label={"Per Diem Amount to Cash"}
                placeholder="Enter Per Diem Amount to Cash"
                validator={simpleValidator}
              />
            </div>

            <div className="">
              <label className="form-label">Per Diem Amount in Card</label>
              <InputField
                inputType="TEXT"
                value={getValue(request, `per_diem_amount_in_card`, "")}
                name={"report_name"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    per_diem_amount_in_card: e.target.value,
                  })
                }
                label={"Per Diem Amount in Card"}
                placeholder="Enter Per Diem Amount in Card"
                validator={simpleValidator}
              />
            </div>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div className="">
              <label className="form-label">Local Commute Amount in Card</label>
              <InputField
                inputType="TEXT"
                value={getValue(request, `local_commute_amount_in_card`, "")}
                name={"report_name"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    local_commute_amount_in_card: e.target.value,
                  })
                }
                label={"Local Commute Amount"}
                placeholder="Enter Local Commute Amount"
                validator={simpleValidator}
              />
            </div>

            <div className="">
              <label className="form-label">
                Accomodation amount loaded to Card
              </label>
              <InputField
                inputType="TEXT"
                value={getValue(
                  request,
                  `accommodation_amount_loaded_to_card`,
                  ""
                )}
                name={"report_name"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    accommodation_amount_loaded_to_card: e.target.value,
                  })
                }
                label={"Accomodation amount"}
                placeholder="Enter Accomodation amount"
                validator={simpleValidator}
              />
            </div>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div className="">
              <label className="form-label">
                Airport Transfer Amount in Card
              </label>
              <InputField
                inputType="TEXT"
                value={getValue(request, `airport_transfer_amount_in_card`, "")}
                name={"report_name"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    airport_transfer_amount_in_card: e.target.value,
                  })
                }
                label={"Airport Transfer Amount"}
                placeholder="Enter Airport Transfer Amount"
                validator={simpleValidator}
              />
            </div>

            <div className="">
              <label className="form-label">Total Forex</label>
              <InputField
                inputType="TEXT"
                value={getValue(request, `total_forex`, "")}
                name={"report_name"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    total_forex: e.target.value,
                  })
                }
                label={"Total Forex"}
                placeholder="Enter Total Forex"
                validator={simpleValidator}
                disabled
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label">
              To Email <span></span>
            </label>
            <ReactMultiEmail
              placeholder="Input To email/s"
              emails={getValue(request, `to_emails`, [])}
              onChange={(_emails: any[]) => {
                setRequest({
                  ...request,
                  to_emails: _emails,
                });
              }}
              // autoFocus={true}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>

          <div className="form-group">
            <label className="form-label">
              CC Email <span></span>
            </label>
            <ReactMultiEmail
              placeholder="Input CC email/s"
              emails={getValue(request, `cc_emails`, [])}
              onChange={(_emails: any[]) => {
                setRequest({
                  ...request,
                  cc_emails: _emails,
                });
              }}
              // autoFocus={true}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>

          <div className="forex-upload-doc">
            <DragAndDropFileComponent
              uploadFile={(e:any)=>handleUploadDocuments(e,props.forexId)}
              name="file"
              types={fileTypes}
              multiple
              classes="bulk-upload_drag-n-drop-wrapper position-absolute"
              fileName={getValue(props, `importedFile.name`, "")}
              setImportTedFile={props.setImportTedFile}
            />

            {getValue(documents, `length`, 0) > 0 &&
              documents.map((item: object, index: number) => {
                return (
                  <div className="d-flex align-items-center ">
                    <div className="p-2 border border-radius__5 d-flex align-items-center w-100 mt-2">
                      <div>
                        <FileLogSvgComponent />
                      </div>
                      <p className="small_text__16 ms-2">
                        {concatString(getValue(item, `name`, ""), 30)}
                        {getValue(item, `extension`, "")}
                      </p>
                    </div>
                    <div
                      className="mx-2"
                      onClick={() => handleRemoveDocuments(index)}
                    >
                      <DeleteSvgComponent />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="fixed_moda_footer_wrapper">
          <div style={{ marginRight: "20px" }}>
            <button
              className="ascent-button ascent-button--header-buttons ascent-button--primary "
              onClick={() => setShowForexPopup(false)}
            >
              Cancel
            </button>
            <button
              className="ascent-button ascent-button--header-buttons ascent-button--secondary ms-3"
              onClick={() => handleSubmit()}
              disabled={submitForexLoading}
            >
              {submitForexLoading
                ? "Please wait..."
                : forexId
                ? "Update"
                : "Create"}
            </button>
          </div>
        </div>
      </div>

      <DeleteModal
        isOpen={isDelete}
        handleModal={handleDelete}
        handleSubmit={handleDeleteFunction}
        deleteValue={deleteValue}
      />
    </>
  );
}

export default ForexPopup;
