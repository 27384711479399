export const getColorStyle = (name: string) => {
  let value = name && name.toLowerCase();
  switch (value) {
    case "completed":
    case "associated":
    case "approved":
    case "active":
    case "reimbursed":
      return {
        color: "#22b378",
        border: "2px solid white",
        background: "#e4f7f1",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };
    case "failed":
    case "not_associated":
    case "rejected":
    case "inactive":
    case "cancel_before_booking":
    case "cancel_after_booking":

      return {
        color: "#d72e38",
        border: "2px solid white",
        background: "#ffebec",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "180px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };

    case "unsubmitted":
    case "recalled":
    case "orange":
    case "sent_back":
      return {
        color: "#f27914",
        border: "2px solid white",
        background: "#fff2e8",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };

    case "skipped":
    case "draft":
      return {
        color: "#797dac",
        border: "2px solid white",
        background: "#f1effb",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };
    case "awaiting_approval":
    case "blue":
      return {
        color: "#408dfb",
        border: "2px solid white",
        background: "#ebf0fe",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
        // fontSize: "16px",
        // lineHeight: "20px",
      };
    case "violet":
      return {
        color: "#8378c3",
        border: "2px solid white",
        background: "#d1d1f9",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };

    default:
      return {};
  }
};

export const getSingleColor = (name: string) => {
  let value = name && name.toLowerCase();
  switch (value) {
    case "associated":
    case "approved":
    case "active":
      return "#22b378";

    case "not_associated":
    case "rejected":
    case "inactive":
      return "#d72e38";

    case "unsubmitted":
    case "orange":
    case "sent_back":
      return "#f27914";

    case "draft":
      return "#797dac";
    case "awaiting_approval":
    case "blue":
      return "#408dfb";

    case "violet":
      return "#8378c3";

    default:
      return {};
  }
};

export function getTextColor(backgroundColor: any) {
  // Convert hexadecimal color code to RGB values
  const hexToRGB = (hex: any) => {
    const bigint = hex && parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // Calculate relative luminance of the color
  const getLuminance = (rgb: any) => {
    const [r, g, b] = rgb.map((c: any) => {
      const s = c / 255;
      return s <= 0.03928 ? s / 12.92 : Math.pow((s + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  const rgb = hexToRGB(backgroundColor);
  const luminance = getLuminance(rgb);

  // Check if the luminance is below a threshold (0.5)
  return luminance < 0.5 ? "#ffffff" : "#000000";
}

export const getColorCode = (alphabet: any) => {
  const colorCodes: any = {
    A: "#D93025",
    B: "#FF8C00",
    C: "#FFDD00",
    D: "#008000",
    E: "#0064B0",
    F: "#6A0DAD",
    G: "#8C1D40",
    H: "#EC4C47",
    I: "#FBBC04",
    J: "#009587",
    K: "#00A8C1",
    L: "#0433FF",
    M: "#D500F9",
    N: "#DB4437",
    O: "#F4B400",
    P: "#0F9D58",
    Q: "#00A4FF",
    R: "#616161",
    S: "#9E9E9E",
    T: "#8D6E63",
    U: "#795548",
    V: "#BDBDBD",
    W: "#607D8B",
    X: "#FF8A65",
    Y: "#FF80AB",
    Z: "#FF4081",
  };
  // Convert input to uppercase
  alphabet = alphabet.toUpperCase();
  // Check if the alphabet exists in the dictionary
  if (alphabet in colorCodes) {
    return colorCodes[alphabet];
  } else {
    return null; // Return null if no color code is found for the input
  }
};

export const getStepColors = (value: number) => {
  switch (value) {
    case 1:
      return "#EDBB99";
    case 2:
      return "#E59866";
    case 3:
      return "#D35400";
    case 4:
      return "#A04000";
    case 5:
      return "#873600";
    case 6:
      return "#6E2C00";
    default:
      return "#6E2C00";
  }
};

export const getStatus = (status: string) => {
  switch (status) {
    case "unsubmitted":
      return "Unsubmitted";
    case "awaiting_approval":
      return "Submitted";
    case "approved":
      return "Approved";
    case "rejected":
      return "Rejected";
    case "reimbursed":
      return "Reimbursed";
    case "expired":
      return "Expired";
    case "archived":
      return "Archived";
    case "recalled":
      return "Recalled";
    default:
      return "";
  }
};
