import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import TripsStickyHeader from "../components/Detail/StickyHeader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import {
  getSpecificTrip,
  getTripValidateApproverFlowType,
  submitTrip,
  uploadTripReceipts,
  deleteTripReceipts,
  adminApproveTrip,
  adminRejectTrip,
  completeTrip,
  getForex,
  cancelBeforeBooking,
  cancelAfterBooking,
} from "@services/trips.service";
import TripsTabNavigation from "../../../../components/Trip/tab-navigation";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { toast } from "react-toastify";
import ReportSubmitModal from "@components/Reports/components/ReportSubmitPopup";
import { mediaUpload } from "@services/upload.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import DeleteModal from "@common/popup/DeleteModal";
import SubmitConfirmModal from "@common/popup/SubmitConfirmModal";
import CommonApproverFlowPopup from "@common/popup/approver-flow";
import TripsTitleHeader from "@components/Trip/trips-title-header";
import TripsListSection from "@components/Trip/trips-list-section";
import TripsRightSection from "@components/Trip/trips-right-section";
import SubmitAdminConfirmModal from "@common/popup/SubmitAdminModal";
import ConfirmModal from "@common/popup/ConfirmModal";
import TravelDocumentPopup from "@common/popup/travel-document-modal";
import { getAllTravelDocumentsAdmin } from "@services/settings.service";
import { useGlobalContext } from "@context/global-context";
import { getSpecificTravelerUser } from "@services/settings.service";
import TravelProfilePopup from "@common/popup/travel-profile-modal";
import NoAccessPage from "@components/common/NoAccess";
import GearLoader from "@components/common/GearLoader";
import ForexPopup from "./forex";

function AdminTripsDetail(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let mainQueryRequest = QueryRequestHelper(urlParams);

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [formInfo, setFormInfo] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getData(true);
      getTripexForexData()
    }
  }, [props.permissionAPITriggered]);
  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      getData(true);
      getTripexForexData()
    }
  }, [window.location.href, props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [activeTab, setActiveTab] = useState("flight");
  const [flightList, setFlightList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [carList, setCarList] = useState([]);
  const [trainList, setTrainList] = useState([]);
  const [busList, setBusList] = useState([]);

  const getData = async (status: boolean) => {
    try {
      setIsLoading(status);
      let resp = await getSpecificTrip(getValue(params, `id`, ""));
      if (resp) {
        setDocuments(getValue(resp, `data.documents`, []));
        setFormInfo(getValue(resp, `data`, {}));
        const flightList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "flight"
              )
            : [];
        const hotelList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "hotel"
              )
            : [];

        const carList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "car"
              )
            : [];

        const trainList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "train"
              )
            : [];

        const busList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "bus"
              )
            : [];

        setFlightList(flightList);
        setHotelList(hotelList);
        setCarList(carList);
        setTrainList(trainList);
        setBusList(busList);

        if (getValue(busList, `length`, 0) > 0) {
          setActiveTab(getValue(busList, `length`, 0) > 0 ? "bus" : "flight");
        }
        if (getValue(trainList, `length`, 0) > 0) {
          setActiveTab(
            getValue(trainList, `length`, 0) > 0 ? "train" : "flight"
          );
        }
        if (getValue(carList, `length`, 0) > 0) {
          setActiveTab(getValue(carList, `length`, 0) > 0 ? "car" : "flight");
        }
        if (getValue(hotelList, `length`, 0) > 0) {
          setActiveTab(
            getValue(hotelList, `length`, 0) > 0 ? "hotel" : "flight"
          );
        }
        if (getValue(flightList, `length`, 0) > 0) {
          setActiveTab(
            getValue(flightList, `length`, 0) > 0 ? "flight" : "flight"
          );
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }

      getTravelInformation(getValue(resp, `data.created_by.id`, ""));
      getTravelProfileInformation(getValue(resp, `data.created_by.id`, ""));
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [forexId, setForexId] = useState<any>(null);

  const getTripexForexData = async () => {
    try {
      let resp = await getForex(getValue(params, `id`, ""));
      if (resp) {
        
        setForexId(getValue(resp, `data.id`, ""))
      }

    } catch (error) {

    }
  };

  const handleEdit = () => {
    navigate(`/admin/trips/edit/${getValue(params, `id`, "")}`);
  };
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [documents, setDocuments] = useState<any>([]);
  const [documentList, setDocumentList] = useState<any>([]);
  const [length, setLength] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStaticLoading, setUploadStaticLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    if (getValue(params, `id`, "")) {
      let lengthOfUploadedFiles = Array.from(e).length;
      setLength(lengthOfUploadedFiles);
    }
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadStaticLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          setDocumentList((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          if (getValue(params, `id`, "")) {
            setUploadLoading(true);
          }
          e = null;
          setUploadStaticLoading(false);
        } else {
          e = null;
          setUploadStaticLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadStaticLoading(false);
      }
    });
  };
  useEffect(() => {
    if (
      length > 0 &&
      getValue(documentList, `length`, 0) > 0 &&
      getValue(documentList, `length`, 0) === length
    ) {
      uploadFiles();
    } else {
      setLength(0);
    }
  }, [length > 0 && getValue(documentList, `length`, 0) === length]);
  const uploadFiles = async () => {
    try {
      let payload = {};
      let requestPayload: any = payload;
      requestPayload["document_ids"] =
        getValue(documents, `length`, 0) > 0
          ? documents.map((item: object) => getValue(item, `id`, ""))
          : [];
      let resp = await uploadTripReceipts(
        getValue(params, `id`, ""),
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        setUploadLoading(false);
        setLength(0);
        setDocumentList([]);
        toast.success(getValue(resp, `message`, ""));
        getData(false);
      } else {
        setLength(0);
        setDocumentList([]);
        setUploadLoading(false);
      }
    } catch (error) {
      setLength(0);
      setDocumentList([]);
      setUploadLoading(false);
    }
  };
  /* ---------------------------- Delete Document -------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let payload = {
        document_id: deleteId,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await deleteTripReceipts(
        getValue(params, `id`, ""),
        queryRequest
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData(false);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                             Trip submission Section                        */
  /* -------------------------------------------------------------------------- */

  const [submitTripLoading, setSubmitTripLoading] = useState(false);
  const handleSubmitTrip = async (status: string) => {
    try {
      setSubmitTripLoading(true);
      let requestPayload: any;
      requestPayload = {
        skip_approver: status,
      };

      let resp;
      if (submitStatus === "approve") {
        let requestPayload = {
          skip_approver: status,
        };
        resp = await adminApproveTrip(
          getValue(params, `id`, ""),
          removeNullOrUndefinedProperties(requestPayload)
        );
      } else {
        let requestPayload = {
          comments: rejectComments,
        };
        resp = await adminRejectTrip(
          getValue(params, `id`, ""),
          removeNullOrUndefinedProperties(requestPayload)
        );
      }
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitTripLoading(false);
        getData(false);
        if (rejectComments) {
          setIsOpen(false);
          setRejectComments("");
        } else {
          setIsConfirmOpen(!isConfirmOpen);
        }
      } else {
        setSubmitTripLoading(false);
      }
    } catch (error) {
      setSubmitTripLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                           Confirm Submit section                           */
  /* -------------------------------------------------------------------------- */
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");
  const handleConfirmModal = (status: string) => {
    setSubmitStatus(status);
    setIsConfirmOpen(!isConfirmOpen);
  };

  /* --------------------------  Report Confirm section  ----------------------- */

  const [rejectComments, setRejectComments] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const openConfirmModal = (status: string) => {
    setSubmitStatus(status);
    handleModal();
  };
  /* -------------------------------------------------------------------------- */
  /*                         Travel Document section                            */
  /* -------------------------------------------------------------------------- */
  const [openDocument, setOpenDocument] = useState(false);
  const toggleDocument = () => {
    setOpenDocument(!openDocument);
  };
  const [travelDoucments, setTravelDocuments] = useState([]);
  const getTravelInformation = async (id: string) => {
    try {
      let payload = {
        user_id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTravelDocumentsAdmin(queryRequest);
      if (resp) {
        setTravelDocuments(getValue(resp, `data.travel_documents`, []));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                         Travel Document section                            */
  /* -------------------------------------------------------------------------- */
  const [isTravelProfile, setisTravelProfile] = useState(false);
  const toggleTravelProfile = () => {
    setisTravelProfile(!isTravelProfile);
  };
  const [travelProfileInfo, setTravelProfileInfo] = useState([]);
  const getTravelProfileInformation = async (id: string) => {
    try {
      let resp = await getSpecificTravelerUser(id);
      if (resp) {
        setTravelProfileInfo(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                Complete section                            */
  /* -------------------------------------------------------------------------- */
  const [isOpen2, setIsOpen2] = useState(false);
  const handleModal2 = () => {
    setIsOpen2(!isOpen2);
  };
  const handleCompleteTrip = async () => {
    try {
      setSubmitTripLoading(true);
      let resp = await completeTrip(getValue(params, `id`, ""));
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitTripLoading(false);
        getData(false);
        handleModal2();
      } else {
        setSubmitTripLoading(false);
      }
    } catch (error) {
      setSubmitTripLoading(false);
    }
  };

    /* -------------------------------------------------------------------------- */
  /*                                Cancel Before Booking section                            */
  /* -------------------------------------------------------------------------- */
  const [isOpen3, setIsOpen3] = useState(false);
  const handleModal3 = () => {
    setIsOpen3(!isOpen3);
  };
  const handleCancelledBeforeBooking = async () => {
    try {
      setSubmitTripLoading(true);
      let resp = await cancelBeforeBooking(getValue(params, `id`, ""));
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitTripLoading(false);
        getData(false);
        handleModal3();
      } else {
        setSubmitTripLoading(false);
      }
    } catch (error) {
      setSubmitTripLoading(false);
    }
  };

    /* -------------------------------------------------------------------------- */
  /*                                Cancel Before Booking section                            */
  /* -------------------------------------------------------------------------- */
  const [isOpen4, setIsOpen4] = useState(false);
  const handleModal4 = () => {
    setIsOpen4(!isOpen4);
  };
  const handleCancelledAfterBooking = async () => {
    try {
      setSubmitTripLoading(true);
      let resp = await cancelAfterBooking(getValue(params, `id`, ""));
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitTripLoading(false);
        getData(false);
        handleModal4();
      } else {
        setSubmitTripLoading(false);
      }
    } catch (error) {
      setSubmitTripLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                Forex Popup                                 */
  /* -------------------------------------------------------------------------- */

  const [showForexPopup, setShowForexPopup] = useState(false);
  const handleForexPopup = () => {
    setShowForexPopup(!showForexPopup);
  };
  
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <TripsStickyHeader
        route={`/admin/trips?${mainQueryRequest}`}
        handleEdit={handleEdit}
        formInfo={formInfo}
        handleSubmitTrip={handleConfirmModal}
        handleRejectTrip={openConfirmModal}
        submitTripLoading={submitTripLoading}
        permissions={getValue(props, `permissions`, [])}
        admin
        handleCompleteTrip={handleModal2}
        cancelBeforeBooking={handleModal3}
        cancelAfterBooking={handleModal4}
        handleForexPopup={handleForexPopup}
      />
      {!isLoading &&
      props.permissionAPITriggered &&
      !getValue(props, `subTabPermissionList`, []).includes("admin_trips") ? (
        <NoAccessPage />
      ) : (
        <>
          {isLoading || !props.permissionAPITriggered ? (
            <GearLoader />
          ) : (
            <div className="trips_main_container trips_details_main_container ">
              <div className="trips_main_container_left">
                <TripsTitleHeader formInfo={formInfo} />
                {(getValue(flightList, `length`, 0) > 0 ||
                  getValue(hotelList, `length`, 0) > 0 ||
                  getValue(carList, `length`, 0) > 0 ||
                  getValue(trainList, `length`, 0) > 0 ||
                  getValue(busList, `length`, 0) > 0) && (
                  <TripsTabNavigation
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    flightList={flightList}
                    hotelList={hotelList}
                    carList={carList}
                    trainList={trainList}
                    busList={busList}
                  />
                )}
                <TripsListSection
                  activeTab={activeTab}
                  formInfo={formInfo}
                  flightList={flightList}
                  hotelList={hotelList}
                  carList={carList}
                  trainList={trainList}
                  busList={busList}
                />
              </div>
              <div className="trips_main_container_right">
                <TripsRightSection
                  formInfo={formInfo}
                  setShowApprovalPopup={setShowApprovalPopup}
                  handleUploadDocuments={handleUploadDocuments}
                  uploadLoading={uploadLoading || uploadStaticLoading}
                  handleDeleteFun={handleDeleteFun}
                  documents={documents}
                  //travel document:
                  showTravelDocument={
                    getValue(travelDoucments, `length`, 0) > 0 ? true : false
                  }
                  toggleDocument={toggleDocument}
                  toggleTravelProfile={toggleTravelProfile}
                  permissions={getValue(props, `permissions`, [])}
                  getData={getData}
                />
              </div>
            </div>
          )}
          <CommonApproverFlowPopup
            showUpdatePopup={showApprovalPopup}
            setShowUpdatePopup={setShowApprovalPopup}
            approvers={getValue(formInfo, `trip_approvers`, [])}
          />
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
          <SubmitAdminConfirmModal
            isOpen={isConfirmOpen}
            handleModal={handleConfirmModal}
            handleSubmit={handleSubmitTrip}
            approvers={getValue(formInfo, `trip_approvers`, [])}
          />
          <ConfirmModal
            isOpen={isOpen}
            handleModal={handleModal}
            handleSubmit={handleSubmitTrip}
            submitLoading={submitTripLoading}
            submitStatus={submitStatus}
            comment={rejectComments}
            setComment={setRejectComments}
          />
          <TravelDocumentPopup
            isOpen={openDocument}
            toggle={toggleDocument}
            list={travelDoucments}
          />
          <TravelProfilePopup
            isOpen={isTravelProfile}
            toggle={toggleTravelProfile}
            info={travelProfileInfo}
          />
          <ConfirmModal
            isOpen={isOpen2}
            handleModal={handleModal2}
            handleSubmit={handleCompleteTrip}
            submitLoading={submitTripLoading}
          />

          <ConfirmModal
            isOpen={isOpen3}
            handleModal={handleModal3}
            handleSubmit={handleCancelledBeforeBooking}
            submitLoading={submitTripLoading}
          />

          <ConfirmModal
            isOpen={isOpen4}
            handleModal={handleModal4}
            handleSubmit={handleCancelledAfterBooking}
            submitLoading={submitTripLoading}
          />
          <ForexPopup
            showForexPopup={showForexPopup}
            setShowForexPopup={setShowForexPopup}
            formInfo={formInfo}
            tripId={getValue(params, `id`, "")}
            forexId={forexId}
          />

        </>
      )}
    </DashboardLayout>
  );
}

export default AdminTripsDetail;
