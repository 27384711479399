import DeleteSvgComponent from "@assets/svg-components/delete";
import EditSvgComponent from "@assets/svg-components/edit";
import { getColorStyle } from "@common/color";
import { convertCurrentDate } from "@common/date-helpers";
import Switch from "@components/list-management/switch";
import { getValue } from "@utils/lodash";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

function ExchangeRateTable(props: any) {
  const { exchangeRateData } = props;
  return (
    <table className="datatable_table">
      <thead className="datatable_table_thead">
        <th className="datatable_table_th"></th>
        <th className="datatable_table_th">
          <h6 className="">Base Currency</h6>
          <p className="">Currency Type</p>
          <p className="">Decimal Places</p>
        </th>
        <th className="datatable_table_th">
          <h6 className="">Conversion Currency</h6>
          <p className="">Currency Type</p>
          <p className="">Decimal Places</p>
        </th>
        <th className="datatable_table_th">
          <h6 className="">Exchange Rate</h6>
        </th>
        <th className="datatable_table_th">
          <h6 className="">Effective Date</h6>
        </th>
        <th className="datatable_table_th">
          <h6 className="">Added On</h6>
        </th>
        <th className="datatable_table_th">
          <h6 className="">Status</h6>
        </th>
      </thead>
      <tbody className="datatable_table_tbody">
        {exchangeRateData.map((item: object, index: number) => {
          return (
            <tr key={index} className="datatable_table_tr">
              <td className="datatable_table_td">
                <img
                  src="/images/payments.svg"
                  className="currency_tbody_td_img img-fluid"
                />
              </td>
              <td className="datatable_table_td">
                <div className="d-flex align-items-center">
                  {/* <NavLink
                    to={`/admin/organisation/currency/edit/${getValue(
                      item,
                      `id`,
                      ""
                    )}`}
                  > */}
                  <h6 className="">
                    {getValue(item, `base_currency.currency_code`, "")}
                  </h6>
                  {/* </NavLink> */}
                  {getValue(item, `base_currency.is_base_currency`, false) && (
                    <>
                      <span className="dot-holder-seperator"></span>
                      <div style={getColorStyle("completed")}>
                        Base Currency
                      </div>
                    </>
                  )}
                </div>
                <p className="">
                  {getValue(item, `base_currency.currency_symbol`, "")}
                </p>
                <p className="">
                  {getValue(item, `base_currency.decimal_places`, "")}
                </p>
              </td>
              <td className="datatable_table_td">
                <div className="d-flex align-items-center">
                  <h6 className="">
                    {getValue(item, `conversion_currency.currency_code`, "")}
                  </h6>
                  {getValue(
                    item,
                    `conversion_currency.is_base_currency`,
                    false
                  ) && (
                    <>
                      <span className="dot-holder-seperator"> </span>
                      <div style={getColorStyle("completed")}>
                        Base Currency
                      </div>
                    </>
                  )}
                </div>
                <p className="">
                  {getValue(item, `conversion_currency.currency_symbol`, "")}
                </p>
                <p className="">
                  {getValue(item, `conversion_currency.decimal_places`, "")}
                </p>
              </td>
              <td className="datatable_table_td">
                <h6 className="">{getValue(item, `exchange_rate`, "")}</h6>
              </td>

              <td className="datatable_table_td">
                <h6 className="">
                  {convertCurrentDate(getValue(item, `effective_date`, ""))}
                </h6>
              </td>

              <td className="datatable_table_td">
                <h6 className="">
                  {convertCurrentDate(getValue(item, `created_at`, ""))}
                </h6>
              </td>

              <td className="datatable_table_td">
                <h6 className="">
                  {getValue(item, `status`, "")}
                </h6>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ExchangeRateTable;
